import { createRouter, createWebHistory } from 'vue-router';
import NewRoser from '../views/Home.vue';
import Download from '../views/Download.vue';
import Protocol from '../views/Protocol.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: NewRoser,
  },
  {
    path: '/download',
    name: 'download',
    component: Download,
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: Protocol,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
