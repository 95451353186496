<template>
  <div class="content">
    <div class="mobile">
      <van-button class="b" block @click="download" type="info" native-type="submit">
        APP 下载
      </van-button>
      <van-button class="b" block @click="goUrl" type="info">
        在线访问
      </van-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive } from 'vue';
import { Field, Button, Popup, Form } from 'vant';
import { Toast } from 'vant';
import http from '../utils/http';
export default defineComponent({
  name: 'promote',
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
  },
  setup () {
    const data = reactive({
      appConfig: {}
    });
    const is_ios = () => {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return 'iOS';
      }
      if (/(Android)/i.test(navigator.userAgent)) {
        return 'Android';
      }
    };
    const getConfig = () => {
      http.post('api/appconfig').then(res => {
        if (res.success) {
          data.appConfig = res.data;
        }
      })
    }
    getConfig();
    const goUrl = () => {
      window.location.href = data.appConfig.online;
    };
    const download = () => {
      if (data.appConfig != null) {
        if (is_ios() == 'Android') {
          window.location.href = data.appConfig.android_download;
        }
        if (is_ios() == 'iOS') {
          window.location.href = data.appConfig.ios_download;
        }
      } else {
        Toast.fail('下载地址不存在');
      }
    };
    return {
      download,
      goUrl
    };
  },
});
</script>
<style lang="scss" scoped>
.content {
  position: relative;
  overflow: auto;
  min-height: 100vh;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  background: url('../assets/bj2.png') no-repeat;
  background-size: 100% 100%;

  .mobile {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translateX(-50%);
    width: 335px;
    margin: 0 auto;
    height: 300px;
    padding-top: 50px;

    .b {
      width: 230px;
      height: 48px;
      display: block;
      margin: 0 auto;
      line-height: 48px;
      margin-top: 40px;
      border: none;
      background: #FFAC9C;
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
}
</style>
