<template>
  <div class="content">
    <div class="mobile">
      <van-form @submit="params.onSubmit">
        <van-field v-model="params.data.mobile" :border="true" type="number" placeholder="请输入您的手机号" />
        <div class="btns">
          <van-field class="code" v-model="params.data.code" :border="true" placeholder="请输入验证码"></van-field>
          <van-button size="small" class="send_sms" @click="getCode" type="primary" :disabled="data.getCodeisWaiting"
            :class="{ disabled: data.getCodeisWaiting }">
            {{ data.getCodeText }}
          </van-button>
        </div>
        <div class="protocol">
          点击“立即拿钱”即代表同意
          <router-link to="protocol" class="xy">《注册协议》</router-link>
        </div>
        <van-button class="b" block type="info" native-type="submit">
          立即拿钱
        </van-button>
      </van-form>
    </div>
  </div>
  <div id="captcha1"></div>
</template>
<script>
import { defineComponent, reactive, ref, onMounted } from 'vue';
import { Field, Button, Popup, Form } from 'vant';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import http from '../utils/http';
import { useRoute, useRouter } from 'vue-router';
import initNECaptchaWithFallback from '../utils/lib/yidun-captcha';

export default defineComponent({
  name: 'promote',
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
  },
  setup () {
    const router = useRoute();
    const route = useRouter();
    const channel = router.query.channel ? router.query.channel : '';
    const captchaIns = ref();
    const codeNumber = ref(0);
    const data = reactive({
      showD: false,
      showRegristerD: false,
      Timer: null,
      getCodeisWaiting: false,
      getCodeText: '获取验证码',
      captcha_data: {},
      captcha: '',
      hiddenCaptcha: true,
      appConfig: {},
    });
    const getConfig = () => {
      http.post('api/appconfig').then(res => {
        if (res.success) {
          data.appConfig = res.data;
          initNECaptcha();
        }
      })
    }
    const initNECaptcha = () => {
      const captchaRef = document.querySelector('#captcha1');
      initNECaptchaWithFallback({
        element: captchaRef,
        captchaId: data.appConfig.wy_xwyzm_captchaId,
        width: '320px',
        mode: 'popup',
        apiVersion: 2,
        popupStyles: {
          position: 'fixed',
          top: '40%'
        },
        onVerify: (err, data) => {
          if (err) return // 当验证失败时，内部会自动refresh方法，无需手动再调用一次
          // 若成功拿到 validate，可向后端发送请求
          let sms_params = {
            mobile: params.data.mobile,
            validate: data.validate,
            dev: 300,
          };
          http.post('api/uncaptcha_code', sms_params).then(res => {
            if (res.code == 200) {
              Toast.success('短信发送成功');
              setTimer();
            } else {
              Toast.fail(res.msg);
            }
          });
        }
      }, function onload (instance) {
        captchaIns.value = instance
      }, function onerror (err) {
        console.warn(err)
      })
    }

    const getCode = () => {
      if (params.data.mobile == '') {
        Toast.fail('请输入你的手机号');
        return;
      }
      console.log(codeNumber.value)
      if (codeNumber.value >= 1) {
        captchaIns.value.verify()
        return
      }
      let sms_params = {
        mobile: params.data.mobile,
        dev: 300,
      };
      let send_number = codeNumber.value += 1;
      localStorage.setItem('codeNumber', send_number);
      http.post('api/uncaptcha_code', sms_params).then(res => {
        if (res.code == 200) {
          Toast.success('短信发送成功');
          setTimer();
        } else {
          Toast.fail(res.msg);
        }
      });
    };
    const setTimer = () => {
      let holdTime = 60;
      data.getCodeisWaiting = true;
      data.Timer = setInterval(() => {
        if (holdTime <= 0) {
          data.getCodeisWaiting = false;
          data.getCodeText = '获取验证码';
          params.data.code = '';
          clearInterval(data.Timer);
          return;
        }
        data.getCodeText = holdTime + 's';
        holdTime--;
      }, 1000);
    };
    const params = reactive({
      data: {
        mobile: '',
        code: '',
      },
      onSubmit: () => {
        if (channel != '') {
          params.data.channel = channel;
        }
        if (params.data.mobile == '') {
          Toast.fail('请输入手机号');
          return false;
        }
        if (params.data.code == '') {
          Toast.fail('验证码不能为空');
          return false;
        }
        http.post('api/register', params.data).then(res => {
          if (res.code == 200) {
            route.push({ name: 'download' });
          } else {
            Toast.fail(res.msg);
            setTimeout(() => {
              route.push({ name: 'download' });
            }, 2000);
          }
        });
      },
    });
    onMounted(() => {
      getConfig();
      const saveCount = localStorage.getItem('codeNumber');
      console.log(saveCount)
      if (saveCount !== null) {
        codeNumber.value = parseInt(saveCount);
      }
    })
    const sendSms = () => {
      //验证图片验证码
      if (data.captcha == '') {
        Toast.fail('请输入图片验证码');
        return;
      }
      let captcha_params = {
        code: data.captcha,
        uniqid: data.captcha_data.uniqid,
      };
      http.post('api/check_captcha', captcha_params).then(res => {
        if (res.code == 200) {
          getCode();
        } else {
          Toast.fail(res.msg);
        }
      });
    };
    const getCaptcha = () => {
      http.post('api/get_captcha').then(res => {
        if (res.code == 200) {
          data.captcha_data = res.data;
        }
      });
    };

    const download = () => {
      route.push({ name: 'download' });
    };
    return {
      data,
      params,
      download,
      sendSms,
      getCaptcha,
      initNECaptcha,
      getConfig,
      captchaIns,
      getCode
    };
  },
});

</script>
<style lang="scss" scoped>
.content {
  position: relative;
  min-height: 100vh;
  background: url('../assets/bj2.png') no-repeat;
  background-size: 100% 100%;

  .mobile {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translateX(-50%);
    width: 335px;
    margin: 0 auto;
    height: 260px;


    .van-form {
      padding: 25px;

      .van-cell {
        margin-top: 10px;
        height: 43px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #979797;
      }

      .btns {
        display: flex;
        align-items: flex-end;
      }

      .code {
        flex: 2;
      }

      .send_captcha {
        flex: 1;
        height: 43px;
        border: 1px solid #979797;
        border-radius: 6px;
        margin-left: 5px;

        img {
          height: 43px;
        }
      }

      .send_sms {
        flex: 1;
        height: 43px;
        margin-left: 10px;
        border: none;
        background: #FFAC9C;
        border-radius: 5px;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        line-height: 40px;
      }

      .protocol {
        height: 30px;
        margin: 18px 0;
        font-size: 13px;
        padding-left: 3px;

        .xy {
          color: #FFAC9C;
        }
      }

      .pwd {
        width: 100%;
        margin: auto;
        padding: 8px 0px;
        text-align: center;
        font-size: 14px;
        color: red;
      }

      .b {
        width: 285px;
        height: 42px;
        display: block;
        margin: 0 auto;
        line-height: 42px;
        border: none;
        background: #FFAC9C;
        font-size: 18px;
        font-weight: 500;
        color: #000;
      }

      .van-cell /deep/ .van-field__control {
        color: #000;
        font-size: 16px;
        font-weight: 400;
      }

      .van-cell /deep/ .van-field__control::-webkit-input-placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #000;
      }
    }
  }
}
</style>
