/* eslint-disable */
import { Toast, Notify, Dialog } from 'vant';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
export default http => {
  // 请求拦截
  http.interceptors.request.use(
    config => {
      config.withCredentials = false; // 需要跨域打开此配置
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  // https://github.com/mzabriskie/axios#interceptors
  http.interceptors.response.use(
    response => {
      return response.data;
    },
    /**
     * This is a central point to handle all
     * error messages generated by HTTP
     * requests
     */
    error => {
      if (!error['response']) {
        return Promise.reject(error);
      }
      const data = error.response.data;
      switch (error.response.status) {
        case 400:
          Toast.fail({
            message: data.message || 'An error occurred, please try again',
            duration: 1500,
            forbidClick: true,
          });
          break;
        case 422:
        case 403:
        case 401:
        case 404:
        case 409:
          Toast.fail({
            message: data.message || 'An error occurred, please try again',
            duration: 1500,
            forbidClick: true,
          });
          break;
        case 500:
          Notify({
            type: 'danger',
            message:
              data.message || "There's a little problem with the server!",
          });
          Toast.clear();
          break;
        case 501:
        case 503:
        default:
          Notify({
            type: 'warning',
            message:
              "There's a little problem with the server！" +
              error.response.status,
          });
          Toast.clear();
      }
      return Promise.reject(error.response);
    }
  );
};
