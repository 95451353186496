import { createApp } from 'vue';
import { Lazyload } from 'vant';
import App from './App.vue';
import router from './router';
import 'vant/lib/index.css';
import 'font-awesome/css/font-awesome.css';
//布局适配
import 'amfe-flexible';
//vant 样式
import mixins from './mixins/index';

const app = createApp(App);
app.config.globalProperties.$mixins = mixins;
app.use(router);
app.use(Lazyload, {
  lazyComponent: true,
});
app.mount('#app');
