import axios from 'axios';
import interceptors from './interceptors';

// allow use http client without Vue instance
const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
});

interceptors(http);

export default http;
