<template>
  <div class="main">
    <div class="content">
      <div v-html="data.content"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive } from 'vue';
import http from '../utils/http';
export default defineComponent({
  name: 'promote',
  components: {},
  setup() {
    const data = reactive({
      content: '',
    });
    const getProtocol = () => {
      http.post('api/agree').then(res => {
        if (res.success) {
          data.content = res.data[0].content;
        }
      });
    };
    getProtocol();
    return {
      data,
    };
  },
});
</script>
<style lang="scss" scoped>
.main {
  position: relative;
  font-size: 14px;
  .content {
    margin: 20px;
  }
}
</style>
